import { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Chip from '@mui/material/Chip'
import TableRow from '@mui/material/TableRow'
import { useTheme } from '@mui/material/styles'
import NumberFormat from 'react-number-format'
import { getTime } from 'utils/dateTools'
import getStatusLabel from 'utils/status'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import { SALE_ROUTE_DETAILS } from 'constants/routes'
import TableCell from './tableCell'

const Row = ({ row }) => {
  const navigate = useNavigate()
  const [selected, setSelected] = useState(false)
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { isMobile } = device

  const handleClick = () => {
    setSelected(!selected)

    cleverTap.event(EVENTS.VENTAS_VIEWED, {
      Click: PV.detalle_venta,
      Device: device.type,
    })

    return navigate(`${SALE_ROUTE_DETAILS.replace(':id', row.id)}`)
  }

  const renderCellGroupClientAndPrice = () => (
    // CLIENT EMAIL & SALES PRICE
    <>
      <TableCell
        sx={{
          color: !isMobile && selected && theme.palette.primary.main,
          fontWeight: !isMobile && selected && theme.fontWeight.semibold,
          minWidth: 200,
          padding: { sm: '12px 8px', xs: '4px 8px 0 16px' },
        }}
      >
        {row.client}
      </TableCell>

      <TableCell
        sx={{
          color: !isMobile && selected && theme.palette.primary.main,
          fontWeight: (isMobile || selected) && theme.fontWeight.semibold,
          minWidth: 80,
          padding: { sm: '12px 8px', xs: '4px 16px' },
          textAlign: { sm: 'left', xs: 'right' },
        }}
      >
        <NumberFormat
          allowEmptyFormatting
          decimalScale="2"
          decimalSeparator="."
          displayType="text"
          fixedDecimalScale
          prefix="$"
          thousandSeparator=","
          thousandsGroupStyle="thousand"
          type="text"
          value={row.total}
        />
      </TableCell>
    </>
  )

  const renderStatusCell = () => (
    // SALES STATUS
    <TableCell
      sx={{
        display: 'flex',
        justifyContent: { sm: 'flex-end', xs: 'flex-start' },
        minWidth: 140,
        padding: { sm: '12px 24px 12px 8px', xs: '12px 24px 12px 16px' },
      }}
    >
      <Chip
        label={getStatusLabel([row.status])}
        shape="status"
        status={row.status}
        sx={{ marginLeft: 0 }}
      />
    </TableCell>
  )

  const renderDesktopVersion = () => (
    <>
      {renderCellGroupClientAndPrice()}
      {renderStatusCell()}
    </>
  )

  const renderMobileVersion = () => (
    <>
      <TableRow>{renderCellGroupClientAndPrice()}</TableRow>
      <TableRow sx={{ borderBottom: `thin solid ${theme.backgrounds[0]}` }}>
        {renderStatusCell()}
      </TableRow>
    </>
  )

  return (
    <>
      <TableRow
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: !isMobile && theme.backgrounds[3],
            cursor: 'pointer',
          },
        }}
      >
        {/* ID (SALE NUMBER) */}
        <TableCell
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.fontWeight.semibold,
            padding: { sm: '12px 8px 12px 24px', xs: '4px 8px 0 16px' },
          }}
        >
          {`#${row.id}`}
        </TableCell>

        {/* TIME OF SALE */}
        <TableCell
          sx={{
            color: !isMobile && selected && theme.palette.primary.main,
            fontWeight: !isMobile && selected && theme.fontWeight.semibold,
            padding: { sm: '12px 8px', xs: '4px 16px 0' },
            textAlign: { sm: 'left', xs: 'right' },
          }}
        >
          {getTime(row.time)}
        </TableCell>

        {!isMobile && renderDesktopVersion()}
      </TableRow>

      {isMobile && renderMobileVersion()}
    </>
  )
}

Row.propTypes = {
  row: PropTypes.shape({
    client: PropTypes.string,
    id: PropTypes.string,
    product_ids: PropTypes.arrayOf(PropTypes.number),
    status: PropTypes.string,
    time: PropTypes.string,
    total: PropTypes.string,
  }).isRequired,
}

export default Row
