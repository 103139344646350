import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { useGlobalContext } from 'store/global'
import { cleverTap, EVENTS, PROPERTIES_VALUES as PV } from 'helpers/clevertap'
import Layout from 'components/Layout'
import Paper from 'components/Paper'
import ContainerCard from 'components/ContainerCard'
import WrapIcon from 'components/WrapIcon'
import UploadFile from 'components/UploadFile'
import TemplatePicker from 'components/TemplatePicker'
import Button from 'components/Button'
import StatusMessages from 'components/StatusMessages'
import { Eyes } from 'components/Icons'
import { ENV, GET_CONFIG_ROUTE } from 'constants/routes'
import { updateInfo } from 'helpers/fetchUtil'
import {
  COVER_IMAGE,
  CUSTOMIZE_YOUR_STORE,
  CHOOSE_DESIGN_STORE,
  CANCEL,
  COLORS,
  PREVIEW_STORE,
  SAVE,
} from 'constants/designPage'
import { SUCCESS_MESSAGE } from 'constants/page'
import ColorPicker from 'components/ColorPicker'
import { useAuthState, useConfigDispatch } from 'store/user'
import UserActions from 'store/user/actions'
import dataColor from 'components/ColorPicker/data'
import dataTemplate from 'components/TemplatePicker/data'
import recordAppsFlyerEvent from 'helpers/appsflyer'
import APPSFLYER_EVENTS from 'constants/appsflyer'
import UPLOAD from 'components/UploadFile/constData.json'

const Design = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { state: navigationState } = useLocation()
  const [submitError, setSubmitError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedColor, setSelectedColor] = useState(dataColor.colors[0].id)
  const [selectedTemplate, setSelectedTemplate] = useState(
    dataTemplate.templates[0].id
  )
  const [errorType, setErrorType] = useState(null)
  const [loading, setLoading] = useState(false)
  const { merchantInfo, merchantToken, storeConfig } = useAuthState()
  const { device, isWebview } = useGlobalContext()

  const dispatch = useConfigDispatch()

  useEffect(() => {
    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.theme_id
    ) {
      setSelectedTemplate(storeConfig.store_settings.theme_id)
    }
    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.color_palette_id
    ) {
      setSelectedColor(storeConfig.store_settings.color_palette_id)
    }

    if (
      storeConfig &&
      storeConfig.store_settings &&
      storeConfig.store_settings.hero_image &&
      !selectedImage
    ) {
      setSelectedImage(storeConfig.store_settings.hero_image)
    }
  }, [storeConfig])

  useEffect(() => {
    cleverTap.event(EVENTS.DISENO_VIEWED, {
      Device: device.type,
      View: PV.viewed,
    })
    recordAppsFlyerEvent(APPSFLYER_EVENTS.checklist.design.view, {
      merchant_id: merchantToken,
    })
  }, [])

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
    setErrorType(null)
  }

  const handleSuccess = ({ imageURL }) => {
    setSelectedImage(imageURL)
    setLoading(false)
  }

  const handleError = ({ errorType: et }) => {
    setErrorType(et)
    setSubmitError(true)
    setLoading(false)
  }
  const handleSubmit = () => {
    cleverTap.event(EVENTS.DISENO_VIEWED, {
      Click: PV.save,
      Device: device.type,
    })

    recordAppsFlyerEvent(APPSFLYER_EVENTS.checklist.design.completed, {
      merchant_id: merchantToken,
    })

    const payload = {
      ...storeConfig.store_settings,
      color_palette_id: selectedColor,
      theme_id: selectedTemplate,
    }

    if (selectedImage) {
      payload.hero_image = selectedImage
    }

    updateInfo(`${ENV.REACT_APP_API_URL}${GET_CONFIG_ROUTE}`, {
      store_settings: payload,
    })
      .then(() => {
        setSubmitSuccess(true)
        dispatch({
          payload: {
            ...storeConfig,
            store_settings: payload,
          },
          type: UserActions.UPDATE_STORE_CONFIG,
        })

        if (navigationState) {
          navigate(navigationState) // to back store page
        }
      })
      .catch(() => {
        setSubmitError(true)
      })
  }

  const handleUploadFile = () => {
    cleverTap.event(EVENTS.DISENO_VIEWED, {
      Click: PV.cover_upload,
      Device: device.type,
    })
  }

  const handleClickTemplatePicker = (template) => {
    setSelectedTemplate(template)

    cleverTap.event(EVENTS.DISENO_VIEWED, {
      Click: PV.design_selection,
      Device: device.type,
    })
  }

  const handleSelectionColor = (color) => {
    setSelectedColor(color)

    cleverTap.event(EVENTS.DISENO_VIEWED, {
      Click: PV.color_selection,
      Device: device.type,
    })
  }

  const handleCancel = () => {
    cleverTap.event(EVENTS.DISENO_VIEWED, {
      Click: PV.cancel,
      Device: device.type,
    })
  }

  const Preview = () => {
    const previewUrl = `${ENV.REACT_APP_STORE_BASE_URL}/${merchantInfo?.publicProfile?.alias}?preview=true&theme=${selectedTemplate}&color=${selectedColor}`

    return (
      <Link href={previewUrl} sx={{ display: 'flex' }} target="_blank">
        <WrapIcon
          sx={{
            backgroundColor: theme.palette.secondary.light,
            cursor: 'pointer',
            float: 'right',
            margin: {
              sm: 'initial',
              xs: `${theme.space[5]}px 0`,
            },
          }}
        >
          <Eyes color={theme.palette.primary.main} />
          <Typography
            color={theme.palette.primary.main}
            sx={{ marginLeft: '11px' }}
            variant="body1"
          >
            {PREVIEW_STORE}
          </Typography>
        </WrapIcon>
      </Link>
    )
  }

  return (
    <Layout>
      <ContainerCard>
        <Paper sx={{ marginBottom: 5 }}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Typography variant="h2">{CUSTOMIZE_YOUR_STORE}</Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: 'flex',
                justifyContent: { sm: 'flex-end', xs: 'flex-start' },
              }}
              xs={12}
            >
              {Preview()}
            </Grid>
          </Grid>
          {isWebview === false && (
            <>
              <Typography variant="h4">{COVER_IMAGE}</Typography>
              <UploadFile
                cover={selectedImage}
                handleClick={handleUploadFile}
                loading={loading}
                onError={handleError}
                onStarted={() => setLoading(true)}
                onSuccess={handleSuccess}
              />
            </>
          )}
          <Typography variant="h4">{CHOOSE_DESIGN_STORE}</Typography>
          <TemplatePicker
            handleClick={handleClickTemplatePicker}
            template={selectedTemplate}
          />
          <Typography variant="h4">{COLORS}</Typography>
          <ColorPicker
            color={selectedColor}
            handleClick={handleSelectionColor}
          />
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button
              onClick={handleCancel}
              sx={{
                backgroundColor: theme.backgrounds[0],
                color: theme.palette.text.primary,
                fontWeight: theme.fontWeight.bold,
                height: 48,
                width: 236,
              }}
            >
              {CANCEL}
            </Button>
            <Button
              color="orange"
              onClick={handleSubmit}
              sx={{ fontWeight: theme.fontWeight.bold, height: 48, width: 236 }}
            >
              {SAVE}
            </Button>
          </Stack>
        </Paper>
        <StatusMessages
          errorFlag={submitError}
          errorMessage={UPLOAD.ERROR_TYPE[errorType]}
          handleClose={() => handleClose()}
          successFlag={submitSuccess}
          successMessage={SUCCESS_MESSAGE}
        />
      </ContainerCard>
    </Layout>
  )
}

export default Design
