import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material'
import Paper from 'components/Paper'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import StatusMessages from 'components/StatusMessages'
import { fetchInfo, postInfo, updateInfo } from 'helpers/fetchUtil'
import { dateExpired } from 'helpers/coupon'
import { parsePrice } from 'utils/form'
import Button from 'components/Button'
import { Cancel } from 'components/Icons'
import {
  ENV,
  POST_COUPONS_ROUTE,
  PROMOTIONS_ROUTE,
  PROMOTIONS_COUPONS_ROUTE,
} from 'constants/routes'
import { CouponEdit } from 'components/Coupon'
import { useGlobalContext } from 'store/global'
import { useCouponContext } from 'components/Coupon/store'
import COUPON_ACTIONS from 'components/Coupon/store/actions'
import { RenderNav, SaveButton } from 'components/Catalog-v2'
import { ModalAdaptable } from 'components/Modal'
import MESSAGE from 'components/Message/constData.json'

const {
  coupon: COUPON,
  qr_code: MAIN,
  sections: SECTIONS,
  discount_counts: DISCOUNT,
} = require('components/Coupon/constData.json')

const NewCoupon = () => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openFinish, setOpenFinish] = useState(false)
  const [inputError, setInputError] = useState(false)
  const [loading, setLoading] = useState(null)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [saveError, setSaveError] = useState(false)
  const [mode, setMode] = useState(COUPON.MODE.CREATE)
  const { device } = useGlobalContext()
  const { couponData, couponDispatch } = useCouponContext()
  const navigate = useNavigate()
  const { state } = useLocation()
  const theme = useTheme()

  const urlBase = `${ENV.REACT_APP_API_URL}${POST_COUPONS_ROUTE}`
  const isDebug = false

  useEffect(async () => {
    if (state?.coupon_code) {
      try {
        setLoading({ edit: true })

        const responseData = await fetchInfo(`${urlBase}/${state.coupon_code}`)
        let { data: coupon } = responseData

        if (dateExpired(coupon.end_date)) {
          coupon = { ...coupon, status: DISCOUNT.STATUS.OPTIONS[2] }
        }
        couponDispatch({
          couponData: coupon,
          type: COUPON_ACTIONS.UPDATE_COUPON,
        })

        setLoading(null)
      } catch (e) {
        setLoading(null)
        // eslint-disable-next-line no-console
        console.log({ e })
      }
    }
  }, [])

  const parseTotalDiscount = (totalDiscount) => {
    const numberParsed = parsePrice(totalDiscount)

    if (couponData.discount_type === COUPON.DISCOUNT_TYPE[1]) {
      // => [ $ ]
      return parseInt(numberParsed, 10)
    }

    return numberParsed
  }

  const getMap = (coupon) => ({
    ...coupon,
    is_visible: !!coupon?.is_visible,
    minimun_purchase: parsePrice(coupon?.minimun_purchase),
    one_per_client: !!coupon?.one_per_client,
    total_discount: parseTotalDiscount(coupon?.total_discount),
    total_to_be_used: parseInt(coupon?.total_to_be_used, 10),
  })

  const handleSave = async () => {
    if (
      !couponData.name ||
      !couponData.coupon_code ||
      !couponData.start_date ||
      !couponData.end_date ||
      !couponData.total_to_be_used ||
      couponData?.redeemed >= parseInt(couponData.total_to_be_used, 10) ||
      !couponData.total_discount ||
      (couponData.discount_type === COUPON.DISCOUNT_TYPE[1] &&
        parseFloat(couponData.minimun_purchase) <
          parseFloat(couponData.total_discount))
    ) {
      setInputError(true)
    } else {
      setLoading({ save: true })
      try {
        if (couponData.status) {
          const response = await updateInfo(`${urlBase}/update`, {
            coupon_code: couponData.coupon_code,
            end_date: couponData.end_date,
            is_visible: !!couponData?.is_visible,
            one_per_client: !!couponData.one_per_client,
            status: couponData.status,
            total_to_be_used: parseInt(couponData.total_to_be_used, 10),
          })

          setMode(COUPON.MODE.EDIT)
          if (response) {
            setLoading(null)
            setSaveSuccess(true)
            setTimeout(() => {
              couponDispatch({ type: COUPON_ACTIONS.CLEAR_COUPON })
              navigate(PROMOTIONS_COUPONS_ROUTE)
            }, MESSAGE.AUTO_HIDE_DURATION)

            // eslint-disable-next-line no-console
            console.log('response save...', response)
          }
        } else {
          setMode(COUPON.MODE.CREATE)
          const response = await postInfo(`${urlBase}/new`, getMap(couponData))

          if (response) {
            setLoading(null)
            setSaveSuccess(true)
            setTimeout(() => {
              couponDispatch({ type: COUPON_ACTIONS.CLEAR_COUPON })
              navigate(PROMOTIONS_COUPONS_ROUTE)
            }, MESSAGE.AUTO_HIDE_DURATION)

            // eslint-disable-next-line no-console
            console.log('response save...', response)
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log({ e })
        setLoading(null)
        setSaveError(true)
      }
    }
  }

  const handleToggleCancel = () => setOpenConfirm(!openConfirm)

  const handleCancel = () => {
    couponDispatch({ type: COUPON_ACTIONS.CLEAR_COUPON })

    navigate(PROMOTIONS_COUPONS_ROUTE)
  }

  const handleClose = () => {
    setSaveError(false)
    setSaveSuccess(false)
  }

  const handleFinish = async () => {
    setLoading({ finish: true })
    setMode(COUPON.MODE.FINISH)
    try {
      const response = await updateInfo(`${urlBase}/update`, {
        coupon_code: couponData.coupon_code,
        status: DISCOUNT.STATUS.OPTIONS[2],
      })

      if (response) {
        setLoading(null)
        setSaveSuccess(true)
        setTimeout(() => {
          couponDispatch({ type: COUPON_ACTIONS.CLEAR_COUPON })
          navigate(PROMOTIONS_COUPONS_ROUTE)
        }, MESSAGE.AUTO_HIDE_DURATION)

        // eslint-disable-next-line no-console
        console.log('response save...', response)
      }
    } catch (error) {
      setLoading(null)
      setSaveError(true)
      // eslint-disable-next-line no-console
      console.log('error to finish:', error)
    }
  }

  const testStore = () => {
    // eslint-disable-next-line no-console
    console.log('DEBUG::COUPON ', couponData)
  }

  const getDescriptionFinish = () =>
    couponData.status ? (
      <>
        <span>{COUPON.CONFIRM.FINISH.DESCRIPTION[0]}</span>
        <b>{` "${couponData?.coupon_code}" `}</b>
        <span>{COUPON.CONFIRM.FINISH.DESCRIPTION[1]}</span>
      </>
    ) : (
      ''
    )
  const ButtonFinish = () => (
    <Button
      disabled={couponData?.status === DISCOUNT.STATUS.OPTIONS[2]}
      onClick={() => setOpenFinish(!openFinish)}
      size="large"
      startIcon={<Cancel color={theme.palette.text.primary} />}
      sx={{ color: theme.palette.text.primary }}
    >
      {DISCOUNT.STATUS_MODAL.FINISHED}
    </Button>
  )

  return (
    <Layout>
      <ContainerCard>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <RenderNav
            back={{ label: MAIN.DISCOUNT_CODES, to: PROMOTIONS_COUPONS_ROUTE }}
            current={{
              label: couponData?.status ? COUPON.EDIT : COUPON.NEW,
              to: `${PROMOTIONS_ROUTE}/${SECTIONS[1].url}`,
            }}
          />
          {couponData?.status && <ButtonFinish />}
        </Stack>

        <Paper sx={{ mt: 1, p: { sm: '24px', xs: '16px' } }}>
          <CouponEdit
            error={inputError}
            loading={loading?.edit}
            mode={couponData.status ? COUPON.MODE.EDIT : COUPON.MODE.CREATE}
          />
          {isDebug && (
            <>
              <Button
                color="cyan"
                onClick={testStore}
                size="full"
                sx={{ mt: 3 }}
              >
                Show Store
              </Button>
            </>
          )}

          <SaveButton
            cancelText={COUPON.BUTTONS.CANCEL}
            disabled={couponData?.status === DISCOUNT.STATUS.OPTIONS[2]}
            fullWidth={device.isMobile}
            handleCancel={handleToggleCancel}
            handleSave={handleSave}
            loading={loading?.save}
            saveText={
              couponData.status ? COUPON.BUTTONS.EDIT : COUPON.BUTTONS.CREATE
            }
            withPaper={device.isMobile}
          />
        </Paper>
      </ContainerCard>

      {/* ModalConfirm Cancel */}
      <ModalAdaptable
        acceptText={COUPON.CONFIRM.CANCEL.BTN_ACCEPT_TEXT}
        cancelText={COUPON.CONFIRM.BTN_CANCEL_TEXT}
        description={COUPON.CONFIRM.CANCEL.DESCRIPTION}
        onAccept={handleCancel}
        onClose={handleToggleCancel}
        open={openConfirm}
        title={COUPON.CONFIRM.CANCEL.TITLE}
      />

      {/* ModalConfirm Finish */}
      <ModalAdaptable
        acceptText={COUPON.CONFIRM.FINISH.BTN_ACCEPT_TEXT}
        cancelText={COUPON.CONFIRM.BTN_CANCEL_TEXT}
        description={getDescriptionFinish()}
        loading={loading?.finish}
        onAccept={handleFinish}
        onClose={() => setOpenFinish(!openFinish)}
        open={openFinish}
        title={COUPON.CONFIRM.FINISH.TITLE}
      />

      <StatusMessages
        errorFlag={saveError}
        errorMessage={COUPON.MESSAGE[mode].ERROR}
        handleClose={handleClose}
        successFlag={saveSuccess}
        successMessage={COUPON.MESSAGE[mode].SUCCESS}
      />
    </Layout>
  )
}

export default NewCoupon
