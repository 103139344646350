import { NavLink, useParams } from 'react-router-dom'
import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material'
import Layout from 'components/Layout'
import ContainerCard from 'components/ContainerCard'
import Button from 'components/Button'
import Paper from 'components/Paper'
import Box from '@mui/material/Box'
import {
  ProductList,
  RenderLabelAndPrice,
  OrderGrid,
} from 'components/ProductList'
import ClientBox from 'components/ClientBox'
import NotesBox from 'components/NotesBox'
import { Calendar, Chevron, DoneCheckCircle, Plus } from 'components/Icons'
import StatusMessages from 'components/StatusMessages'
import Loader from 'components/Loader'

import getStatusLabel from 'utils/status'
import { getOrderById } from 'hooks/useOrders'
import { getDateTitle, getTime } from 'utils/dateTools'
import { useGlobalContext } from 'store/global'
import { useAuthState } from 'store/user'
import { SALES_ROUTE, SALE_ROUTE_DETAILS } from 'constants/routes'
import ORDER_PATCH_SUCCESS_MESSAGE from 'constants/apiResponses'
import { patchOrder } from 'helpers/fetchUtil'
import { getMailTo } from 'utils/links'
import {
  COUPON_TEXT,
  DELIVERY_COST_TEXT,
  DELIVERED_STATUS,
  NUMBER_LABEL,
  SALE_LABEL,
  TITLE_SALE_DETAILS,
  MARK_AS_DELIVERED,
  MESSAGE,
  SALE_TEXT_NAV,
  CANCEL_ORDER,
  PENDING_ORDER_STATUS,
  SUBTOTAL_AMOUNT_TEXT,
  TOTAL_AMOUNT,
} from 'constants/salesPage'

const OrderDetail = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [orderDelivered, markAsDelivered] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [msgType, setMsgType] = useState('DELIVERED')
  const { id: orderId } = useParams()
  const theme = useTheme()
  const { device } = useGlobalContext()
  const { merchantInfo } = useAuthState()
  const { publicProfile } = merchantInfo

  const { order: orderData } = getOrderById(orderId)

  const handleClose = () => {
    setSubmitError(false)
    setSubmitSuccess(false)
  }

  const persistOrder = async (payload) => {
    try {
      const response = await patchOrder(orderId, payload)

      if (
        response.data &&
        response.data.msg &&
        response.data.msg === ORDER_PATCH_SUCCESS_MESSAGE
      ) {
        setSubmitSuccess(true)
      } else {
        setSubmitError(true)
      }
    } catch (e) {
      setSubmitError(true)
    }
  }

  const handleSaveNotes = (notes) => {
    setMsgType('NOTES')
    persistOrder({
      merchant_notes: notes,
    })
  }

  const handleMarkDelivered = async () => {
    await persistOrder({
      order_status: DELIVERED_STATUS,
    })
    setSubmitSuccess(true)
    markAsDelivered(true)
    setMsgType('DELIVERED')
  }

  const renderOrderId = () => {
    if (device.isMobile) {
      return `${orderId.split('-')[0]}...`
    }

    return orderId
  }

  const calculateSubTotal = () => {
    let subtotal = 0

    orderData.products.forEach((p) => {
      subtotal += parseFloat(p?.price) * parseInt(p.quantity, 10)
    })

    return subtotal
  }

  const renderNav = () => (
    <Stack
      alignItems="center"
      flexDirection="row"
      sx={{
        '& > * ': {
          color: theme.palette.text.primary,
          fontWeight: theme.fontWeight.semibold,
          textDecoration: 'none',
        },
      }}
    >
      <NavLink to={SALES_ROUTE}>{SALE_TEXT_NAV}</NavLink>
      <Chevron />
      <NavLink
        style={{ color: theme.palette.black.main }}
        to={{ path: `${SALE_ROUTE_DETAILS}/:id`, query: { orderId } }}
      >
        {SALE_LABEL}
        {orderId}
      </NavLink>
    </Stack>
  )

  const renderSubtotalDetail = () => {
    if (!orderData.delivery_cost && !orderData.coupon) {
      return null
    }
    const subtotalPrice = calculateSubTotal()

    const renderSubtotalItem = (children) => (
      <OrderGrid sx={{ borderTop: `1px solid ${theme.backgrounds[1]}` }}>
        {children()}
      </OrderGrid>
    )

    const deliveryRender = () => (
      <RenderLabelAndPrice
        name={DELIVERY_COST_TEXT}
        price={orderData.delivery_cost}
        variant="body1"
      />
    )

    const couponRender = () => (
      <>
        <RenderLabelAndPrice
          name={COUPON_TEXT}
          price={`-${orderData.coupon.total_discount_amount}`}
          variant="body1"
        />
        <span>(cod. {orderData.coupon.code})</span>
        {orderData.coupon.discount_type === '%' && (
          <span> {orderData.coupon.total_discount}% off</span>
        )}
      </>
    )

    return (
      <>
        <OrderGrid>
          <RenderLabelAndPrice
            name={SUBTOTAL_AMOUNT_TEXT}
            price={subtotalPrice}
            variant="body1"
          />
        </OrderGrid>

        {orderData.delivery_cost && renderSubtotalItem(deliveryRender)}
        {orderData.coupon && renderSubtotalItem(couponRender)}
      </>
    )
  }

  if (!orderData) {
    return (
      <Layout>
        <ContainerCard>
          <Paper
            sx={{
              height: '100vh',
              pl: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
              pr: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
            }}
          >
            <Loader />
          </Paper>
        </ContainerCard>
      </Layout>
    )
  }

  const date = new Date(orderData.created_at)
  const dateTitle = `${getDateTitle(
    orderData.created_at.slice(0, 10)
  )} ${date.getFullYear()}`

  return (
    <Layout>
      <ContainerCard>
        {renderNav()}

        <Stack
          sx={{ flexDirection: { md: 'row', xs: 'column' }, paddingTop: 2 }}
        >
          <Box>
            <Paper sx={{ p: { xs: '8px 0 0 0' } }}>
              {/* TITLE */}
              <Stack
                sx={{
                  alignItems: { sm: 'center', xs: 'flex-start' },
                  flexDirection: { sm: 'row', xs: 'column' },
                  justifyContent: { sm: 'space-between' },
                  p: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
                }}
              >
                <Typography color="black" variant="h2">
                  {TITLE_SALE_DETAILS}
                </Typography>

                {/* STATUS */}
                <Chip
                  label={getStatusLabel([
                    orderDelivered ? DELIVERED_STATUS : orderData.order_status,
                  ])}
                  shape="status"
                  status={
                    orderDelivered ? DELIVERED_STATUS : orderData.order_status
                  }
                  sx={{ ml: 0, mt: { sm: 0, xs: `${theme.space[3]}px` } }}
                />
              </Stack>

              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
                sx={{
                  backgroundColor: theme.backgrounds[0],
                  p: `${theme.space[5]}px`,
                }}
              >
                {/* NUMBER SALE */}
                <Typography
                  color="text.primary"
                  fontWeight={theme.fontWeight.semibold}
                  variant="body1"
                >
                  {NUMBER_LABEL}
                  {SALE_LABEL} {renderOrderId()}
                </Typography>
                {/* DATE */}
                <Typography
                  color="text.primary"
                  fontWeight={theme.fontWeight.semibold}
                  sx={{ display: 'flex' }}
                  variant="body1"
                >
                  <Calendar size={1.4} />
                  <i>&nbsp;&nbsp;</i>
                  {dateTitle}
                </Typography>
              </Stack>

              {/* PRODUCT LIST */}
              <Grid container>
                <ProductList products={orderData?.products} />
                {renderSubtotalDetail()}

                <Grid
                  item
                  sx={{
                    backgroundColor: theme.backgrounds[0],
                    p: `${theme.space[4]}px ${theme.space[5]}px`,
                  }}
                  xs={12}
                >
                  <RenderLabelAndPrice
                    name={TOTAL_AMOUNT}
                    price={orderData.total_paid_amount}
                    priceBold
                    textBold
                    variant="h4"
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: { sm: 'row', xs: 'column-reverse' },
                    justifyContent: 'center',
                    m: { sm: `${theme.space[5]}px`, xs: `${theme.space[4]}px` },
                  }}
                  xs={12}
                >
                  <Button
                    href={getMailTo(
                      orderData?.payer.email,
                      publicProfile.email,
                      dateTitle,
                      getTime(orderData.created_at),
                      parseFloat(orderData.total_paid_amount)
                    )}
                    size="large"
                    startIcon={<Plus rotate={45} size={1.5} />}
                    sx={{
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.primary.main,
                      mr: { sm: '2vw', xs: 0 },
                      mt: { sm: 0, xs: `${theme.space[6]}px` },
                      width: { sm: 182, xs: '100%' },
                    }}
                    type="link"
                  >
                    {CANCEL_ORDER}
                  </Button>

                  {orderData.order_status === PENDING_ORDER_STATUS &&
                    orderDelivered === false && (
                      <Button
                        color="orange"
                        onClick={handleMarkDelivered}
                        size="large"
                        startIcon={<DoneCheckCircle color="white" />}
                        sx={{
                          ml: { sm: '2vw', xs: 0 },
                          width: { sm: 250, xs: '100%' },
                        }}
                      >
                        {MARK_AS_DELIVERED}
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Paper>
            {/* NotesBox Desktop version */}
            <Box sx={{ mt: `${theme.space[5]}px` }}>
              {!device.isMobile && (
                <NotesBox
                  handleSubmit={handleSaveNotes}
                  value={orderData?.merchant_notes}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: { md: 'block', xs: 'flex' },
              flexDirection: { md: 'column', sm: 'row', xs: 'column' },
              maxWidth: { md: 214 },
              ml: { md: `${theme.space[5]}px`, xs: 0 },
              mt: { md: 0, xs: `${theme.space[4]}px` },
              padding: 0,
              width: '100%',
            }}
          >
            <ClientBox payer={orderData.payer} />
            {/* NotesBox Mobile version */}
            {device.isMobile && (
              <NotesBox
                handleSubmit={handleSaveNotes}
                value={orderData?.merchant_notes}
              />
            )}
          </Box>
        </Stack>

        <StatusMessages
          errorFlag={submitError}
          errorMessage={
            msgType === 'DELIVERED'
              ? MESSAGE.DELIVERED.ERROR
              : MESSAGE.NOTES.ERROR
          }
          handleClose={() => handleClose()}
          successFlag={submitSuccess}
          successMessage={
            msgType === 'DELIVERED'
              ? MESSAGE.DELIVERED.SUCCESS
              : MESSAGE.NOTES.SUCCESS
          }
        />
      </ContainerCard>
    </Layout>
  )
}

export default OrderDetail
