import { Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { MenuProvider } from 'components/Menu/store'
import { AuthProvider } from 'store/user'
import { GlobalProvider } from 'store/global'
import { SyncProvider } from 'store/sync'
import { ProductProvider } from 'components/Catalog-v2/store'
import { CouponProvider } from 'components/Coupon/store'
import GlobalStyles from 'components/GlobalStyles'
import theme from 'theme'
import CatalogMfe from 'pages/catelogMfe'
import NotFound from 'pages/notFound'
import Help from 'pages/help'
import Onboarding from 'pages/onboarding'
import Store from 'pages/store'
import Sales from 'pages/sales'
import OrderDetail from 'pages/orderDetail'
import Reports from 'pages/reports'
import Design from 'pages/design'
import Settings from 'pages/settings'
import Tutorials from 'pages/tutorials'
import FAQ from 'pages/faq'
import Articles from 'pages/articles'
import TaC from 'pages/termsAndConditions'
import Login from 'pages/login'
import PostBuilder from 'pages/postBuilder'

import {
  NOT_FOUND_ROUTE,
  HELP_ROUTE,
  STORE_ROUTE,
  SALES_ROUTE,
  SALE_ROUTE_DETAILS,
  REPORTS_ROUTE,
  PROMOTIONS_ROUTE,
  PROMOTIONS_QR_ROUTE,
  PROMOTIONS_COUPONS_ROUTE,
  PROMOTIONS_COUPON_ROUTE,
  PROMOTIONS_POST_BUILDER,
  DESIGN_ROUTE,
  ONBOARDING_ROUTE,
  SETTINGS_ROUTE,
  TUTORIALS_ROUTE,
  FAQ_ROUTE,
  TERMS_AND_CONDITIONS,
  TUTORIALS_ARTICLES_ROUTE,
  CATALOG,
  LOGIN,
} from 'constants/routes'

import { Promotions, QR, NewCoupon, Coupons } from './pages/promotions'

const App = () => (
  <StyledEngineProvider injectFirst>
    <GlobalProvider>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route element={<Login />} path={LOGIN} />
        </Routes>
        <AuthProvider>
          <SyncProvider>
            <MenuProvider>
              <ProductProvider>
                <CouponProvider>
                  <Routes>
                    <Route element={<Store />} path={STORE_ROUTE} />
                    <Route element={<NotFound />} path={NOT_FOUND_ROUTE} />
                    <Route element={<Help />} path={HELP_ROUTE} />
                    <Route element={<Sales />} path={SALES_ROUTE} />
                    <Route
                      element={<OrderDetail />}
                      path={SALE_ROUTE_DETAILS}
                    />
                    <Route element={<Reports />} path={REPORTS_ROUTE} />
                    <Route element={<Design />} path={DESIGN_ROUTE} />
                    <Route element={<Promotions />} path={PROMOTIONS_ROUTE} />
                    <Route element={<QR />} path={PROMOTIONS_QR_ROUTE} />
                    <Route
                      element={<Coupons />}
                      path={PROMOTIONS_COUPONS_ROUTE}
                    />
                    <Route
                      element={<NewCoupon />}
                      path={PROMOTIONS_COUPON_ROUTE}
                    />
                    <Route
                      element={<PostBuilder />}
                      path={PROMOTIONS_POST_BUILDER}
                    />
                    <Route element={<Settings />} path={SETTINGS_ROUTE} />
                    <Route element={<Tutorials />} path={TUTORIALS_ROUTE} />
                    <Route element={<Onboarding />} path={ONBOARDING_ROUTE} />
                    <Route element={<Navigate to={STORE_ROUTE} />} path="/" />
                    <Route element={<FAQ />} path={FAQ_ROUTE} />
                    <Route
                      element={<Articles />}
                      path={TUTORIALS_ARTICLES_ROUTE}
                    />
                    <Route element={<TaC />} path={TERMS_AND_CONDITIONS} />
                    <Route element={<CatalogMfe />} path={`${CATALOG}/*`} />
                  </Routes>
                </CouponProvider>
              </ProductProvider>
            </MenuProvider>
          </SyncProvider>
        </AuthProvider>
      </ThemeProvider>
    </GlobalProvider>
  </StyledEngineProvider>
)

export default App
